<template>
  <v-container>
    <!-- ############# -->
    <v-layout row wrap justify-space-between class="mb-2">
      <v-flex xs6 sm4 md4 lg4 class="mb-1">
        <v-btn
          :color="$store.state.secondaryColor"
          outlined
          small
          @click="goBack"
        >
          <v-icon small left>mdi-arrow-left</v-icon>
          <span class="caption">{{ $t("back") }}</span>
        </v-btn>
      </v-flex>
      <v-flex xs6 sm4 md4 lg4 class="mb-1 text-right">
        <v-btn
          small
          outlined
          class="mr-3"
          @click="editShift($route.params.id)"
          :color="$store.state.primaryColor"
        >
          {{ $t("edit") }}
          <v-icon small right>edit</v-icon>
        </v-btn>
        <v-btn
          small
          outlined
          class="mr-3"
          @click="delDialog = true"
          :color="$store.state.secondaryColor"
        >
          {{ $t("delete") }}
          <v-icon small right>delete</v-icon>
        </v-btn>
      </v-flex>
    </v-layout>
    <!-- ################ -->

    <v-dialog v-model="delDialog" persistent max-width="290">
      <v-card>
        <div class="text-center">
          <v-btn class="my-5" outlined large fab color="red darken-4">
            <v-icon>warning</v-icon>
          </v-btn>
        </div>

        <v-card-text class="text-center">
          <!-- <v-icon>delete</v-icon> -->
          <p>{{ $t("are you sure") }}?</p>
          <p>
            {{ $t("do you really want to delete") }}
            <span class="font-weight-bold">{{ shift.shiftName }}</span
            >?
          </p>
        </v-card-text>
        <v-card-actions>
          <v-btn
            :color="$store.state.secondaryColor"
            text
            small
            @click="delDialog = false"
          >
            {{ $t("cancel") }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            :color="$store.state.primaryColor"
            text
            small
            @click="deleteShift"
          >
            {{ $t("confirm") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      shaped
      top
      rounded="pill"
      v-model="snackbar"
      :timeout="timeout"
      color="blue-grey"
    >
      {{ $t("deleted") }} !

      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="snackbar = false">
          {{ $t("close") }}
        </v-btn>
      </template>
    </v-snackbar>
    <div v-if="pleaseWait" class="mt-3">
      <PleaseWait></PleaseWait>
    </div>
    <div v-else>
      <v-layout row wrap justify-center v-if="shift">
        <v-flex xs12 sm12 md12 lg12>
          <span class="text-h6 font-weight-black">{{ shift.shiftName }}</span>
        </v-flex>
      </v-layout>
      <v-layout row wrap justify="center" align="center">
        <v-flex
          class="pa-5 mx-1 elevation-2"
          style="border: 1px solid; border-radius: 5px"
        >
          <v-list dense class="">
            <v-list-item class="body-1 d-flex flex-wrap">
              <v-col cols="12" md="6">
                <span class="font-weight-black text-capitalize"
                  >{{ $t("start time") }}:
                </span>
                <span class="">{{ shift.startTime }} </span>
              </v-col>
              <v-col cols="12" md="6">
                <span class="font-weight-black text-capitalize"
                  >{{ $t("end time") }}:
                </span>
                <span class="">{{ shift.endTime }} </span>
              </v-col>
            </v-list-item>

            <v-divider></v-divider>
          </v-list>
        </v-flex>
      </v-layout>
    </div>
  </v-container>
</template>

<script>
import db from "@/plugins/fb";
import PleaseWait from "@/components/templates/PleaseWait";

export default {
  components: {
    PleaseWait,
  },
  data: () => ({
    shift: "",
    delDialog: false,
    snackbar: false,
    pleaseWait: true,
    timeout: 2000,
  }),

  created() {
    this.getShiftDetails();
  },

  methods: {
    getShiftDetails() {
      db.collection("shifts")
        .doc(this.$route.params.id)
        .get()
        .then((doc) => {
          this.shift = doc.data();
          this.pleaseWait = false;
        })
        .catch((error) => {
          console.log("Error getting  document:", error);
          this.snackError = true;
        });

      console.log(this.shift);
    },

    editShift(shiftId) {
      this.$router.push({ name: "UpdateShift", params: { id: shiftId } });
    },

    deleteShift() {
      db.collection("shifts")
        .doc(this.$route.params.id)
        .delete()
        .then(() => {
          this.snackbar = true;
          setTimeout(() => this.goBack(), 2000);
        })
        .catch((error) => {
          console.error("Error removing document: ", error);
        });
    },

    goBack() {
      window.history.back();
    },
  },
};
</script>

<style></style>
